import { Config } from './Config';
import { HttpService } from './http/HttpService';
import { Observable } from 'rxjs';
import { Response } from '@angular/http';
import { Injectable } from '@angular/core';

@Injectable()
export class Api {

  constructor(private http: HttpService) {

  }

  /**
   * 获取图片验证码
   */
  public getVcodeImage(): Observable<Response> {
    return this.http.get("/topsystem/vcode");
  }

  /**
   * 获取手机短信验证码(使用代理)
   */
  public getSMS(params: any): Observable<Response> {
    return this.http.post("/topsystem/smsvcode/app", params, true);
  }

  //通过图片验证码获取手机短信验证码
  public getSMSVcode(params: any): Observable<Response> {
    return this.http.post("/topsystem/smsvcode", params, true);
  }
  public getSMSVcodeWithPsw(phone): Observable<Response> {
    return this.http.post("/student/sms/send/resetpassword/" + phone, true);
  }


  /**
   * 获取code(使用代理)
   */
  public getCode(params: any): Observable<Response> {
    return this.http.post("/user/mobile/sms", params, true);
  }

  /**
   * 学号获取code(使用代理)
   */
  public getCodeForStudentId(params: any): Observable<Response> {
    return this.http.post("/user/login/college/no", params, true);
  }


  public getCodeForStudentNo(params: any): Observable<Response> {
    return this.http.post("/user/login", params, true);
  }

  /**
   * 通过code获取token(使用代理)
   */
  public getToken(params: any): Observable<Response> {
    return this.http.post("/api/oauth2/access_token", params, true);
  }

  public getAccount(): Observable<Response> {
    return this.http.get("/account_user/talent");
  }

  public getUserInfo() {
    return this.http.get("/user");
  }

  public getStudentIdByMobile(params: any): Observable<Response> {
    return this.http.post("/user/nobymobile", params, true);
  }

  public restPassword(params: any): Observable<Response> {
    return this.http.post("/user/password/resetbymobile", params, true);
  }

  /**
   * 获取极光web初始化参数
   * {
            "appkey": "",
            "random_str": "",
            "signature": "",
            "timestamp": ""
        }
   */
  // public getJmInitData(): Observable<Response> {
  //   return this.http.get("im/auth_payload");
  // }

  /**
   * 测试接口(使用代理)
   */
  public loginForTest(params: any): Observable<Response> {
    return this.http.post("/user/login/test/" + params.loginname + "/" + params.password, params, true);
  }

  /**
   * 引导页面数据
   * 职位总数
   */
  public getPositionTotal(params: any): Observable<Response> {
    return this.http.get("/topsystem/dataconfig/position/data", params);
  }

  //获取匹配职位数
  public getRecommendationTotal(): Observable<Response> {
    return this.http.get("/position/recommendation/count");
  }

  //检测是否绑定学号（检测学校是否已导入学生信息）
  public checkStudentId() {
    return this.http.post("/student/talent/check");
  }

  public checkStudentMobile(mobile) {
    return this.http.get("/talent/" + mobile + "/bindno/check");
  }

  //绑定学号
  public bindingStudentId(params: any) {
    return this.http.post("/student/bind/talent", params);
  }

  //手机号绑定
  public bindingMobile(params: any) {
    return this.http.post("/talent/bindno/merge", params);
  }

  /**
   * 在线
   */
  public online(): Observable<Response> {
    return this.http.get("/account/refresh/onlinetime");
  }

  /**
   * 获取简历信息
   */
  public getResume(): Observable<Response> {
    return this.http.get("/resume/default");
  }

  //获取分享简历key
  public getResumeShareKey(resumeid, channels): Observable<Response> {
    return this.http.get("/resume/" + resumeid + "/share/" + channels + "/key");
  }

  public getResumeFromKey(key): Observable<Response> {
    return this.http.get("/resume/" + key + "/view/share");
  }

  /**
   * 创建人才账户及简历
   * 获取accound id和resume id
   * 极光用户和密码
   */
  public registerUserInfo(params: any): Observable<Response> {
    return this.http.post("/talent/onestep", params);
  }
  /**
   * 极简完善用户信息
   */
  public talentUpdateUserInfo(params: any): Observable<Response> {
    return this.http.post("/talent/update", params);
  }
  /**
   * 获取推荐的职位类别
   */
  public getRecommendedJob(): Observable<Response> {
    return this.http.get("/resume/post_type");
  }

  //获取推荐非订阅职位类别
  public getOtherJob(): Observable<Response> {
    return this.http.get("/resume/post_type/other");
  }

  public updateJobSubscription(params: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/job_target/career/update", params);
  }

  public updateCareer(params: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/career/update", params);
  }

  //根据选择的职位类别 获取专业技能标签
  public getCareerSkill(): Observable<Response> {
    return this.http.get("/resume/career/skill");
  }

  //修改简历职位技能标签
  public updateProSkill(params: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/pro_skill/update", params);
  }

  // 获取简历职位技能标签
  public getProSkill(): Observable<Response> {
    return this.http.get("/resume/" + Config.getInstance().resume_id + "/pro_skill");
  }

  /**
   * 获取用户订阅职位
   */
  public getJobSubscription(): Observable<Response> {
    return this.http.get("/resume/" + Config.getInstance().resume_id + "/job_target/career");
  }

  /**
   * 获取简历意向职位
   */
  public getJobFilter(): Observable<Response> {
    return this.http.get("/resume/" + Config.getInstance().resume_id + "/job_target");
  }

  public updateJobFilter(params: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/job_target/update", params);
  }

  /**
   * 首页
   * 搜索
   */
  public jobSearch(params: any): Observable<Response> {
    return this.http.get("/position/searcher", params);
  }

  //猜你想搜
  public jobKeyword(): Observable<Response> {
    //return this.http.get("position/keyword");
    return this.http.get("/resume/keywords");
  }

  /**
   * 首页
   * 获取职位推荐
   */

  // public getJobRecommend(params: any): Observable<Response> {
  //   return this.http.get("position/recommendation", params);
  // }

  /* 小程序 获取职位列表 */
  public getJobRecommend(params: any): Observable<Response> {
    return this.http.get("/position/searcher/mp", params);
  }

  public getJobDetails(id: any): Observable<Response> {
    return this.http.get("/position/" + id + "/public");
  }

  //获取热门城市
  public getHotCity(): Observable<Response> {
    return this.http.get("/position/hot/city");
  }

  /**
   * 获取企业和hr信息
   */
  public getCompanyById(departmentid: any) {
    return this.http.get("/department/" + departmentid + "/public");
  }

  public getCompanyAllJob(numberid: any) {
    return this.http.get("/department/" + numberid + "/position/list");
  }

  public getCompanyAllPosition(departmentid: any, collegeid: any) {
    return this.http.get("/department/position/list/public?departmentid=" + departmentid + "&collegeid=" + collegeid);
  }

  public getPublisherAllJob(numberid: any) {
    return this.http.get("/department/" + numberid + "/position");
  }

  //获取企业招聘会，宣讲会
  public getCompanyAllMC(params: any) {
    return this.http.get("/mc/department/apply/list", params);
  }

  /**
   * 收藏
   */
  public postLike(numberid: any) {
    return this.http.post("/position/" + numberid + "/like");
  }

  public postUnLike(numberid: any) {
    return this.http.post("/position/" + numberid + "/unlike");
  }

  /**
   *  简历投递
   */
  public deliverResume(params: any): Observable<Response> {
    return this.http.post("/delivery", params);
  }

  /**
   * 热门职位
   */
  public getJobHot(params: any): Observable<Response> {
    return this.http.get("/position/hot", params);
  }

  /**
   * 根据名称模糊匹配返回字典
   * 需要添加 级别 level
   */
  public matchSearch(dicttype, params: any): Observable<Response> {
    return this.http.get("/dict/" + dicttype + "/matching", params);
  }

  public getDict(dicttype: string): Observable<Response> {
    return this.http.get("/dict/" + dicttype);
  }

  //获取子集
  public getDictChild(dicttype: string, parentcode: any) {
    return this.http.get("/dict/" + dicttype + "/" + parentcode);
  }

  public getDictContrast(source_dict_type: string, source_code: string, result_dict_type: string) {
    return this.http.get("/dict/contrast/" + source_dict_type + "/" + source_code + "/" + result_dict_type);
  }
  public getDictContrastv3(source_dict_type: string, source_code: string, result_dict_type: string) {
    return this.http.get("/dict/contrast2/" + source_dict_type + "/" + source_code + "/" + result_dict_type);
  }

  /**
   * 消息(聊天)
   */
  public getChatDialogueList(params: any): Observable<Response> {
    return this.http.get("/im/dialogue/list", params);
  }

  //获取所有对话列表
  public getChatDialogueAllList(params: any): Observable<Response> {
    return this.http.get("/im/dialogue/alllist", params);
  }

  public getChatList(id: any, params: any): Observable<Response> {
    return this.http.get("/im/chat/" + id + "/list", params);
  }

  public sendChat(params: any): Observable<Response> {
    return this.http.post("/im/chat/create", params);
  }

  public sendChatImage(params: any): Observable<Response> {
    return this.http.post("/im/image/upload", params);
  }

  public getChatDialogue(jm_user_name: any): Observable<Response> {
    return this.http.get("/im/dialogue/" + jm_user_name + "/info");
  }

  public setChatRead(dlid: any): Observable<Response> {
    return this.http.post("/im/chat/" + dlid + "/read");
  }

  public getChatMenu(accountid: any): Observable<Response> {
    return this.http.get("/im/official_account/" + accountid + "/menu");
  }

  //获取招聘会
  public getMilkRoundById(id: any): Observable<Response> {
    return this.http.get("/milkround/" + id + "/public");
  }

  //预约招聘会
  public reminderMilkRoundById(id: any): Observable<Response> {
    return this.http.post("/milkround/" + id + "/apply/talent");
  }

  //获取预约招聘会
  public getMilkRoundOrder(params: any): Observable<Response> {
    return this.http.get("/milkround/apply/list/talent", params);
  }

  /**
   * 获取弹幕 招聘会现场
   * @param id
   */
  public getLiveMilkRoundBulletScreen(id: any): Observable<Response> {
    return this.http.get("/milkround/" + id + "/live/barrage");
  }

  //获取招聘现场企业
  public getLiveMilkRoundList(id: any, params: any): Observable<Response> {
    return this.http.get("/milkround/" + id + "/live/talent", params);
  }

  //获取宣讲会
  public getCampustalkById(id: any): Observable<Response> {
    return this.http.get("/campustalk/" + id + "/public");
  }

  //预约宣讲会
  public reminderCampustalkById(id: any): Observable<Response> {
    return this.http.post("/campustalk/" + id + "/reserve");
  }

  //获取预约宣讲会
  public getCampustalkOrder(params: any): Observable<Response> {
    return this.http.get("/campustalk/reserve/list", params);
  }

  //检查投递状态
  public chatCheck(params: any): Observable<Response> {
    return this.http.post("/im/chat/check", params);
  }

  //置顶
  public topChat(id: any): Observable<Response> {
    return this.http.post("/im/dialogue/" + id + "/top");
  }

  public shieldChat(id: any): Observable<Response> {
    return this.http.post("/im/dialogue/" + id + "/shield");
  }

  public reportChat(id: any, code: any): Observable<Response> {
    return this.http.post("/im/dialogue/" + id + "/tip_off?reason=" + code);
  }

  //筛选
  public getChatFilterPostType(): Observable<Response> {
    return this.http.get("/im/dialogue/list/post_type");
  }

  /**
   * 面试
   */
  public getInterviewAgenda(params: any): Observable<Response> {
    return this.http.get("/interview/talent/list", params);
  }

  public getInterviewDetails(id: any): Observable<Response> {
    return this.http.get("/interview/talent/" + id);
  }

  public reponseInterview(interviewid: any, feedback_status: any): Observable<Response> {
    return this.http.post("/interview/" + interviewid + "/feedback?feedback_status=" + feedback_status);
  }

  public reponseOffer(interviewid: any, res: any): Observable<Response> {
    return this.http.post("/interview/" + interviewid + "/feedback/offer?is_entry=" + res);
  }

  /**
   * 我的
   * 获取简历完整度
   */
  public getResumeLevel(): Observable<Response> {
    return this.http.get("/resume/" + Config.getInstance().resume_id + "/level");
  }

  public getCount(): Observable<Response> {
    return this.http.get("/talent/listcount/simple");
  }

  //沟通过
  public getMyChatHistory(params: any): Observable<Response> {
    return this.http.get("/im/dialogue/list/position", params);
  }

  //已投递
  public getMyDelivery(params: any): Observable<Response> {
    return this.http.get("/delivery/mydelivery", params);
  }

  /**
   * 获取人才信息
   */
  public getProfile(): Observable<Response> {
    return this.http.get("/talent");
  }

  public getAvatar(): Observable<Response> {
    return this.http.get("/talent/profile_picture");
  }

  public updateProfile(params: any): Observable<Response> {
    return this.http.post("/talent/update/app", params);
  }

  // public updateProfileAddress(params: any): Observable<Response> {
  //   return this.http.post("talent/address/update", params);
  // }

  public updateAvatar(params: any): Observable<Response> {
    return this.http.post("/talent/profile_picture/app", params);
  }

  //User用户（没有accound id）
  public updateUserAvatar(params: any): Observable<Response> {
    return this.http.post("/user/update/avatar/app", params);
  }

  public updateUserName(params: any): Observable<Response> {
    return this.http.post("/talent/name/update", params);
  }

  public updateUserGender(params: any): Observable<Response> {
    return this.http.post("/talent/gender/update", params);
  }

  public updateUserOverseaWork(params: any): Observable<Response> {
    return this.http.post("/talent/overseas_work/update", params);
  }

  public updateUserBirthday(params: any): Observable<Response> {
    return this.http.post("/talent/birthdate/update", params);
  }

  public updateWechat(params: any): Observable<Response> {
    return this.http.post("/talent/wechat/update", params);
  }

  public updateEmail(params: any): Observable<Response> {
    return this.http.post("/talent/email/update", params);
  }

  public updateEntryTime(params: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/entry", params);
  }

  /**
   * 添加教育经历
   *
   */
  public addEducation(params: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/education_backgrounds/create", params);
  }

  public getEducationById(id: any): Observable<Response> {
    return this.http.get("/resume/" + Config.getInstance().resume_id + "/education_backgrounds/" + id);
  }

  public updateEducationById(params: any, subid: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/education_backgrounds/" + subid + "/update", params);
  }

  public deleteEducationById(subid: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/education_backgrounds/" + subid + "/delete");
  }

  /**
   * 添加工作经历
   */
  public addWork(params: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/work_experience/create", params);
  }

  public getWorkById(id: any): Observable<Response> {
    return this.http.get("/resume/" + Config.getInstance().resume_id + "/work_experience/" + id);
  }

  public getJobPostList(): Observable<Response> {
    return this.http.get("/resume/post_type/other");
  }

  public updateWorkById(params: any, subid: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/work_experience/" + subid + "/update", params);
  }

  public deleteWorkById(subid: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/work_experience/" + subid + "/delete");
  }

  //获取工作描述模板
  public getWorkDescriptionModel(post_type: any): Observable<Response> {
    return this.http.get("/position/" + post_type + "/post_desc");
  }

  /**
   * 作品
   */
  public addOpus(params: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/works/create", params);
  }

  public getOpusById(id: any): Observable<Response> {
    return this.http.get("/resume/" + Config.getInstance().resume_id + "/works/" + id);
  }

  public updateOpusById(params: any, subid: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/works/" + subid + "/update", params);
  }

  public deleteOpusById(subid: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/works/" + subid + "/delete");
  }

  /**
   * 上传图片
   */
  public uploadPhoto(params: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/uploadimage", params);
  }

  /**
   * 项目比赛
   */
  public addMatch(params: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/project_experience/create", params);
  }

  public getMatchById(id: any): Observable<Response> {
    return this.http.get("/resume/" + Config.getInstance().resume_id + "/project_experience/" + id);
  }

  public updateMatchById(params: any, subid: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/project_experience/" + subid + "/update", params);
  }

  public deleteMatchById(subid: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/project_experience/" + subid + "/delete");
  }

  /**
   * 培训经历
   */
  public addTraining(params: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/training_experience/create", params);
  }

  public getTrainingById(id: any): Observable<Response> {
    return this.http.get("/resume/" + Config.getInstance().resume_id + "/training_experience/" + id);
  }

  public updateTrainingById(params: any, subid: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/training_experience/" + subid + "/update", params);
  }

  public deleteTrainingById(subid: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/training_experience/" + subid + "/delete");
  }

  /**
   * 证书
   */
  public addCertificate(params: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/certificates/create", params);
  }

  public getCertificateById(id: any): Observable<Response> {
    return this.http.get("/resume/" + Config.getInstance().resume_id + "/certificates/" + id);
  }

  public updateCertificateById(params: any, subid: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/certificates/" + subid + "/update", params);
  }

  public deleteCertificateById(subid: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/certificates/" + subid + "/delete");
  }

  /* 删除在校职务 */
  public deleteSchoolPositionById(subid: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/school_position/" + subid + "/delete");
  }


  /* 更新在校职务 */
  public updateSchoolPositionById(params, subid: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/school_position/" + subid + "/update", params);
  }

  /* 添加在校职务 */
  public addSchoolPositionById(params): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/school_position/create", params);
  }

  /**
   * 技能
   */
  public updateSkill(params: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/skill/update", params);
  }

  // 自我描述
  public updateSelf_evaluate(params: any): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/self_evaluate/update", params)
  }

  //获取推荐技能，包括专业技能和通用技能
  public getSkillRecommend(params: any): Observable<Response> {
    return this.http.get("/resume/skill", params);
  }

  //根据订阅的职位获取简历技能标签
  public getSkillByCareer(): Observable<Response> {
    return this.http.get("/resume/" + Config.getInstance().resume_id + "/career/skill");
  }

  /**
   * 谁看过我（简历浏览记录）
   */
  public getResumeView(params: any): Observable<Response> {
    return this.http.get("/resume/view", params);
  }

  //对我感兴趣
  public getResumeArchived(params: any): Observable<Response> {
    return this.http.get("/resume/archived", params);
  }

  /**
   * 收藏
   */
  public getCollection(params: any): Observable<Response> {
    return this.http.get("/position/like", params);
  }

  /**
   * 意见反馈
   */
  public postFeedback(params: any): Observable<Response> {
    return this.http.post("/feedback", params);
  }

  /**
   * 修改人才姓名
   */
  public updateName(params: any): Observable<Response> {
    return this.http.post("/talent/name/update", params);
  }

  //app升级检测
  public checkAppUpdate(): Observable<Response> {
    return this.http.get("/topsystem/talent/app_version/newest");
  }

  //获取用户信息
  public getUserMobile(): Observable<Response> {
    return this.http.get("/user");
  }

  //修改用户手机号
  public updateUserMobile(params: any): Observable<Response> {
    return this.http.post("/user/mobile/change", params);
  }

  public updateUserPassword(params: any): Observable<Response> {
    return this.http.post("/user/password/change", params);
  }

  //校园
  //学校主页
  public getCollegeById(id: any): Observable<Response> {
    return this.http.get("/college/" + id);
  }

  public getCollegeByCode(code: any): Observable<Response> {
    return this.http.get("/college/code/" + code);
  }

  //学校招聘会
  public getCollegeMilkRound(params: any): Observable<Response> {
    return this.http.get("/milkround/list/public", params);
  }

  //学校宣讲会
  public getCollegeCampusTalk(params: any): Observable<Response> {
    return this.http.get("/campustalk/list/public", params);
  }

  //就业信息列表
  public getArticle(params: any): Observable<Response> {
    return this.http.get("/article/list/public", params);
  }

  public getArticleDetailById(id: any): Observable<Response> {
    return this.http.get("/article/" + id + "/public");
  }

  //校园头部广告
  public getCollegeBanner(params: any): Observable<Response> {
    return this.http.get("/booth/list/public", params);
  }

  //学校导航栏
  public getCollegeNav(collegeid: any, nav_category: any): Observable<Response> {
    return this.http.get("/nav/" + collegeid + "/" + nav_category + "/info");
  }

  public getCollegeFaq(collegeid: any): Observable<Response> {
    return this.http.get("/consult/" + collegeid + "/list/public");
  }

  public createCollegeFaq(collegeid: any, params: any) {
    return this.http.post("/consult/" + collegeid + "/create", params);
  }

  public collegeSearchFile(collegeid: any, params: any): Observable<Response> {
    return this.http.get("/student/" + collegeid + "/archives_query", params);
  }

  //学生获取高校合作公司信息列表
  public getCollegeCompany(params: any): Observable<Response> {
    return this.http.get("/cooperation/college/list", params);
  }

  public getCollegeCompanyOfWap(collegeid: any, params: any): Observable<Response> {
    return this.http.get("/cooperation/college/" + collegeid + "/list", params);
  }

  //获取院系列表
  public getCollegeFaculty(id: any): Observable<Response> {
    return this.http.get("/college/" + id + "/faculty/list/public");
  }

  //获取学校专业
  public getCollegeFacultyMajor(id: any): Observable<Response> {
    return this.http.get("/major/" + id + "/list/public");
  }

  //职业咨询
  public getCollegeCareerConsult(params: any): Observable<Response> {
    return this.http.get("/careerconsult/list/public", params);
  }

  public getCollegeCareerConsultDetails(id: any): Observable<Response> {
    return this.http.get("/careerconsult/n" + id + "/public");
  }

  //咨询预约
  public collegeCareerConsultOrder(id: any, params: any): Observable<Response> {
    return this.http.post("/careerconsult/n" + id + "/apply", params);
  }

  //咨询预约列表
  public getCollegeCareerConsultOrderList(params: any): Observable<Response> {
    return this.http.get("/careerconsult/apply/talent/list", params);
  }
  //学生取消咨询预约列表
  public cancelConsultOrder(id: any, params: any): Observable<Response> {
    return this.http.post("/careerconsult/" + id + "/cancel", params);
  }
  //学籍信息  student/talent  老接口
  public getStudentInfo(id): Observable<Response> {
    return this.http.get("/student/field/form?talentid=" + id);
  }

  public updateStudentInfo(sid: any, params: any): Observable<Response> {
    return this.http.post("/student/" + sid + "/talent/update3", params);
  }

  //推荐企业 校友企业
  public getCollegeDepartment(params: any): Observable<Response> {
    return this.http.get("/department/college/list/public", params);
  }

  //获取系统广告
  public getSystemAdv(): Observable<Response> {
    return this.http.get("/booth/client/pctalent/list/public");
  }

  //  根据对方极光用户名获取对话记录信息
  public getDialogueUserInfo(username: any): Observable<Response> {
    return this.http.get("/im/dialogue/" + username + "/info");
  }

  //  获取聊天记录
  public getDialogueUserChatList(jmusername: any, params): Observable<Response> {
    return this.http.get("/im/chat/jm/" + jmusername + "/list", params);
  }

  //  获取聊天记录
  public getMsgUnread(): Observable<Response> {
    return this.http.get("/im/chat/unread");
  }

  /* 获取签约学生基本信息表单 */
  public getStudentSingUp(): Observable<Response> {
    return this.http.get("/signup/talent/dataform");
  }

  /* 获取签约学生基本信息 */
  public getStudentSing(): Observable<Response> {
    return this.http.get("/signup/talent/form");
  }
  public getStudentSingV3(): Observable<Response> {
    return this.http.get("/signup/V3/talent/newdataform");
  }

  /* 根据就业类别获取签约表单（人才 高校） */
  public getEmployment_type(employment_type, params): Observable<Response> {
    return this.http.get("/signup/talent/" + employment_type + "/form", params);
  }
  /* 根据就业类别获取签约表单V3（人才 高校） */
  public getEmployment_typeV3(employment_type, params): Observable<Response> {
    return this.http.get("/signup/v3/talent/" + params + "/" + employment_type + "/form", params);
  }
  /* GET 获取签约字段中的档案信息表单字段配置（人才）*/
  public getArchives_form(year): Observable<Response> {
    return this.http.get("/signup/v3/talent/" + year + "/field/archives");
  }

  /* 根据就业类别获取签约表单_签约核查（人才 高校） */
  public getEmployment_type_signup_verify(employment_type, params): Observable<Response> {
    return this.http.get("/signup_verify/talent/" + employment_type + "/form", params);
  }


  /* 人才创建签约信息 （人才 高校） */
  public postSignDetails(parmas): Observable<Response> {
    return this.http.post("/signup/talent/initiate", parmas);
  }
  public postSignDetailsv3(parmas): Observable<Response> {
    return this.http.post("/signup/v3/talent/paper/submit", parmas);
  }

  //数据校验校验
  public formAtValidation(data): Observable<Response> {
    return this.http.post(`/topsystem/formatvalidation`, data);
  }

  // 获取学生选择专业列表
  public getSource_major(): Observable<Response> {
    return this.http.get("/major/list/talent");
  }

  // 签约图片上传
  public postSignUploadImage(parmas): Observable<Response> {
    return this.http.post("/signup/uploadimage", parmas);
  }


  //线上签约上传的私有图片
  public postLineSignUploadImage(parmas): Observable<Response> {
    return this.http.post("/agreement_record/talent/upload/private/file", parmas);
  }

  // 修改签约
  public postSignUpUpdate(signupid, params): Observable<Response> {
    return this.http.post("/signup/talent/" + signupid + "/update", params);
  }
  public postSignUpUpdatev3(signupid, params): Observable<Response> {
    return this.http.post("/signup/v3/talent/" + signupid + "/update", params);
  }

  public postUserVerification(parmas): Observable<Response> {
    return this.http.post("/topsystem/formatvalidation", parmas);
  }

  //下载旧协议书
  public downloadagreement(): Observable<Response> {
    return this.http.getFile("/signup/talent/downloadagreement");
  }
  //下载新协议书
  public downloadNewagreement(recordid): Observable<Response> {
    return this.http.getFile("/signup/v3/talent/downloadagreement/" + recordid);
  }

  // 获取档案进度
  public getMyRecord(collegeid, params): Observable<Response> {
    return this.http.get("/student/" + collegeid + "/archives_query", params);
  }

  // 获取讲座预约列表
  public getEmploymentChairList(params): Observable<Response> {
    return this.http.get("/career_lecture_appointment/student/list", params);
  }

  // 获取就业指导讲座信息
  public getEmploymentChairDetails(id): Observable<Response> {
    return this.http.get("/career_lecture/student/" + id);
  }

  // 获取就业指导讲座预约信息
  public getEmploymentChairOrder(id): Observable<Response> {
    return this.http.get("/career_lecture_appointment/student/" + id);
  }

  // 取消就业指导讲座预约
  public postCancelEmploymentChair(id, params): Observable<Response> {
    return this.http.post("/career_lecture_appointment/student/cancel/" + id, params);
  }

  // 敏感词判断
  public sensitiveWord(params): Observable<Response> {
    return this.http.post("/sensitive_word/talent/check/result", params);
  }

  // 获取语言类型
  public getLanguages(): Observable<Response> {
    return this.http.get("/dict/language");
  }

  // 获取语言级别
  public getLanguagesRank(): Observable<Response> {
    return this.http.get("/dict/language_level");
  }

  // 添加语言能力
  public addLanguagesById(params): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/languages/create", params);
  }

  // 修改语言能力
  public updateLanguagesById(params, subid): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/languages/" + subid + "/update", params);
  }

  // 删除语言能力
  public deleteLanguagesById(subid): Observable<Response> {
    return this.http.post("/resume/" + Config.getInstance().resume_id + "/languages/" + subid + "/delete",);
  }


  // 修改求职意向
  public setJobStatus(params): Observable<Response> {
    return this.http.post("/talent/job_status/update", params)
  }

  // 获取职位类别
  public getPositionSort(): Observable<Response> {
    return this.http.get("/dict/post_type")
  }

  // 浏览量统计
  public postStatisticBrower(parmas): Observable<Response> {
    return this.http.post("/statistic/brower/create", parmas, true)
  }

  //线上签约接口 开始

  //获取学生的签约列表
  public getSignList(parmas={}): Observable<Response> {
    return this.http.get("/signup/v3/talent/list",parmas)
  }
  public checkSign(): Observable<Response> {
    return this.http.get("/signup/v3/talent/check")
  }
  public checkSignNo(): Observable<Response> {
    return this.http.get("/signup/notosign")
  }

  //获取学生签约表单
  public getSignFromList(): Observable<Response> {
    return this.http.get("/signup/v3/talent/list")
  }

  //生成企业链接，
  public makeDepartmentLink(params): Observable<Response> {
    return this.http.post("/signup/v3/talent/online", params)
  }

  //提交备案
  public commitSiginBeiAn(params): Observable<Response> {
    return this.http.post("/signup/v3/talent/paper/submit", params)
  }
  public commitSiginOnLine(recordid): Observable<Response> {
    return this.http.post("/agreement_record/talent/online/record/" + recordid + "/sign")
  }
  public commitSiginOnLinev3(recordid, data): Observable<Response> {
    return this.http.post("/signup/v3/talent/online/record/" + recordid + "/sign", data)
  }

  // 线上签约（签约中/退回签约）重新提交
  public commitDeparmtentinfo(recordid, data): Observable<Response> {
    return this.http.post("/signup/v3/talent/online/" + recordid + "/deparmtentinfo/submit", data)
  }
  //备案签约修改
  public updataSignBeiAn(recordid, params): Observable<Response> {
    return this.http.post(`/signup/v3/talent/paper/${recordid}/update`, params);
  }

  //检测签约记录是新的还是旧的
  public checkSignupNewAndOld(): Observable<Response> {
    return this.http.get("/signup/talent/get_sign_type")
  }

  //获取新签约的form表单
  public getSignNewForm(recordid): Observable<Response> {
    return this.http.get("/agreement_record/talent/record/" + recordid + "/dataform")
  }
  public getSignNewFormV3(recordid): Observable<Response> {
    //GET /signup/v3/talent/record/{recordid}/dataform
    return this.http.get("/signup/v3/talent/record/" + recordid + "/dataform")
  }

  //获取签约配置
  public getSignInfoSetting(): Observable<Response> {
    return this.http.get("/signup/v3/talent/getfield/newest")
  }


  //拒绝线上签约
  public unsignOnLineSign(recordid, data): Observable<Response> {
    return this.http.post("/signup/v3/talent/record/online/" + recordid + "/unsign", data)
  }

  //同意线上签约
  public signOnLineSign(recordid): Observable<Response> {
    return this.http.post("/signup/v3/talent/record/online/" + recordid + "/sign")
  }
  //线上签约高校退回修改
  public updateOnlineSign(recordid, data): Observable<Response> {
    return this.http.post(`/signup/v3/talent/online/${recordid}/update`, data);
  }

  //生成并下载协议书
  public makeXieYiWord(data): Observable<Response> {
    return this.http.post("/signup/v3/talent/offline", data)
  }

  //提交线下签约
  public commitDownLineSign(recordid, data): Observable<Response> {
    return this.http.post("/signup/v3/talent/offline/" + recordid + "/submit", data)
  }
  public deleteAllSign(): Observable<Response> {
    return this.http.get("/agreement_record/talent/deleteall")
  }
  //人才解约
  public cancelSign(data): Observable<Response> {
    return this.http.post("/sign_cancel_request/talent/create", data)
  }
  //获取解约的状态
  public getCancelSignState(recordid): Observable<Response> {
    return this.http.get("/sign_cancel_request/talent/audit/" + recordid)
  }
  //根据生源地获取派遣地址
  public getDispatchAddress(native_place): Observable<Response> {
    return this.http.get(`/signup/v3/talent/${native_place}/dispatchaddress`)
  }

  //签约撤回
  public Revocation(recordid): Observable<Response> {
    return this.http.post(`/signup/v3/revocation/${recordid}`);
  }

  //修改档案信息  签约审核通过支持修改档案信息
  public updateProfileAddress(recordid, params) {
    return this.http.post(`/signup/v3/talent/record/${recordid}/profile_address/update`, params);
  }

  //线上签约接口 结束


  //重置密码
  resetpassword(data) {
    return this.http.post("/student/resetpassword", data);
  }

  // 检查当前职位7天内是否重复投递
  public checkPresentPositionIsSend(positionId): Observable<Response> {
    return this.http.get("/delivery/" + positionId + "/getdeliveryinfo")
  }

  // 是否邮寄报到证
  public postEmailNeedRegistry(params): Observable<Response> {
    return this.http.post("/signup/need_registry", params);
  }


  // 意见反馈 上传图片信息
  public uploadFeedbackImage(data) {
    return this.http.post("/topsystem/qiniu", data)
  }

  // 获取问题反馈列表
  public getFeedBackMsgList(dicttype, params) {
    return this.http.get("/dict/" + dicttype, params);
  }

  // 用户反馈问题
  public postFeedBackMsg(params) {
    return this.http.post("/feedback/create", params);
  }

  // 就业调查列表
  public getEmploymentSurveyList() {
    return this.http.get("/questionnaire/student/list");
  }

  // 就业调查单个详情 问题
  public getOnlySurveyQuestion(questionid) {
    return this.http.get("/questionnaire/talent/" + questionid + "/question");
  }

  // 就业调查单个详情  选项 回答
  public getOnlySurveyAnswer(questionid) {
    return this.http.get("/questionnaire/talent/" + questionid + "/answer");
  }

  // 就业调查单个详情  提交答案
  public PostSurveySubmit(questionid, params) {
    return this.http.post("/questionnaire/talent/create/" + questionid + "/answer", params);
  }

  // 生源信息 撤回审核
  public postCancelAudit(sid) {
    return this.http.post("/student/" + sid + "/talent/cancel/audit")
  }
  public getopenWindows() {
    return this.http.get("/student/getopenWindows");
  }

  // 修改密码格式验证
  public updatePassWordFormatVerify(params) {
    return this.http.post("/topsystem/formatvalidation", params)
  }

  // 签约中心 撤回审核
  public postSignCancelAudit(sid) {
    return this.http.post("/signup/revocation/" + sid)
  }


  // 检测学号是否绑定手机号
  public checkNoIsBindMoblie(no, params) {
    return this.http.get("/talent/" + no + "/bindmobile/check", params)
  }

  // 修改绑定手机号码
  public changeBindPhone(mobile) {
    return this.http.post("/talent/mobile/change/bind", mobile)
  }

  // 是否可以提交报到证
  public checkUserCanSubmit() {
    return this.http.get("/signup_registry/talent/can_submit")
  }

  // 获取报到证相关信息
  public getSignReportCard() {
    return this.http.get("/signup_registry/talent/signup_info")
  }

  //报到证图片提交
  public postLineReportCardloadImage(parmas): Observable<Response> {
    return this.http.post("/signup_registry/talent/upload/private/file", parmas);
  }

  // 提交报到证
  public postSignupRegistry(params) {
    return this.http.post("/signup_registry/talent/submit", params)
  }

  // 撤回报到证信息
  public signupRegistryRevocation(registryid) {
    return this.http.post("/signup_registry/talent/revocation/" + registryid)
  }

  // 申请改派
  public postApplyReassignment(params) {
    return this.http.post("/signup_registry/talent/reassignment/apply", params)
  }

  //  获取学生菜单配置
  public getStudentMenu(params) {
    return this.http.get("/student_menu/talent", params)
  }

  // 签约核查
  public getSignInspect() {
    return this.http.get("/signup_verify/talent");
  }

  // 签约核查提交
  public postInspectSignForm(signupid, params) {
    return this.http.post("/signup_verify/talent/update/" + signupid, params)
  }

  // 签约核查撤回
  public postSignInspectRecall(signupid) {
    return this.http.post("/signup_verify/revocation/" + signupid)
  }


  // 获取广告 管理后台
  public postCdPublication(params) {
    return this.http.post("/api/CdPublication/GetBatchList_P", params)
  }

  public getArticleColumnList(college_id) {
    return this.http.get(`/article/column/namelist/public?collegeid=${college_id}`);
  }


  public getParticipantList(params) {
    return this.http.get("/participate_statistic/talent/list", params)
  }

  // 检查手机号验证码是否可用
  public checkSmsvcodeVerify(params) {
    return this.http.post("/topsystem/smsvcode/verify", params, true);
  }

  // 签约中心 检测自定义字段是否更新
  public checkSignCodeUpdate(params) {
    return this.http.post('/signup/customize_field/detect/change', params)
  }



  // 获取当前时间是否在填报时间
  public employment_intension_time(params) {
    return this.http.get("/employment_intension_time/talent/current_time", params)
  }


  // 求职意向提交
  public post_employment_intension_submit(data) {
    return this.http.post("/employment_intension_submit/talent/save", data)
  }

  // 获取就业类别字段配置
  public get_employment_type(params) {
    return this.http.get("/employment_intension_submit/talent/field/employment_type/" + params)
  }

  public post_sanCode_login(params) {
    return this.http.post("/user/login", params, true)
  }

  // 下载简历
  public postDownResume(id, params) {
    return this.http.getFile("/resume/talent/" + id + "/download", params)
  }

  public getEmploymentOffice(params) {
    return this.http.get("/college/employment_office", params)
  }
  //签约3.0判断
  public getCollegesignupSwitch(collegeid) {
    return this.http.get("/signup/v3/" + collegeid + "/signup3switch")
  }
  // 就业推荐表相关接口

  //是否第一次填写就业表
  public getIsFirstWrite(): Observable<Response> {
    return this.http.get("/recommend/isfirstwrite")
  }
  //添加就业推荐表
  public addRecommend(params): Observable<Response> {
    return this.http.post("/recommend/add", params)
  }

  //获取就业推荐表
  public getRecommend(): Observable<Response> {
    return this.http.get("/recommend/get")
  }
  //修改就业推荐表
  public updateRecommend(params): Observable<Response> {
    return this.http.post("/recommend/update", params)
  }
  //下载
  public downloadRecommend(): Observable<Response> {
    return this.http.getFile("/recommend/download")
  }
  //获取高校简易设置
  public getCollegeSimple(): Observable<Response> {
    return this.http.get("/recommend/setting/get/collegesimple")
  }

  // 获取困难生学生信息
  public getHardshipCheck() {
    return this.http.get("/student/talent/hardship")
  }

  //获取困难生类型列表
  public gethardshiptype() {
    return this.http.get(`/student/talent/hardshiptype/name/list`);
  }

  //确认困难
  public posthardshiptype(data) {
    return this.http.post(`/student/talent/hardshiptype/confirm`, data);
  }


  // 河师大-就业推荐表
  //获取就业推荐表
  public getRecommendList(params: any): Observable<Response> {
    return this.http.get("/v2/recommend/talent/list", params);
  }

  //撤回就业推荐表
  public revokeRecommend(collegeid) {
    return this.http.post("/v2/recommend/talent/" + collegeid + "/revoke");
  }


  // 档案管理
  // 人才获取生源信息
  public getNewStudentInfo() {
    return this.http.get("/v2/student_archives/talent/student");
  }

  // 人才撤回档案
  public studentArchivesRevoke(id) {
    return this.http.post("/v2/student_archives/talent/" + id + "/revoke");
  }

  // 人才获取档案
  public getSudentArchives() {
    return this.http.get("/v2/student_archives/talent");
  }

  // 人才添加档案
  public studentArchivesAdd(data) {
    return this.http.post("/v2/student_archives/talent/add", data);
  }
  // 人才更新档案
  public studentArchivesUpdate(data) {
    return this.http.post("/v2/student_archives/talent/update", data);
  }

  // 人才上传档案附件
  public uploadStudentArchivesImage(data) {
    return this.http.post("/v2/student_archives/talent/upload/image", data)
  }

    // 人才根据生源地获取派遣地址
    public getDispatchaddresse(data) {
      return this.http.get("/v2/student_archives/talent/"+data+"/dispatchaddress");
    }

  // 就业推荐表上传照片
  public recommendUploadPhoto(data) {
    return this.http.post("/recommend/upload/photo", data)
  }
  // 获取届别
  public getYearList() {
    return this.http.get("/college/year_list/talent");
  }

    /** 获取学校名列表 */
    public getCollegeNameList(params: any) {
      return this.http.get("/college/namelist/public", params);
    }

   // 档案管理增加单位名称模糊查询
   public searchDispatchaddress(data) {
    return this.http.get("/v2/student_archives/talent/dispatchaddress/search?keywords="+data);
  }

     // 档案管理增加单位名称模糊查询
     public getEmployment_status(code) {
      return this.http.get("/dict/contrast/employment_status/" + code + "/employment_type");
    }
   // 获取相似简历列表
   public getRecommend_list(positionid) {
      return this.http.get("/position/recommend/list?positionid="+positionid);
  }
    
    // 一键投递简历
    public recommendBatch(data) {
      return this.http.post("/delivery/recommend/batch", data)
    }
}

