import { BaseService } from './../../services/BaseService';
import { Api } from './../../provider/Api';
import {Injectable} from '@angular/core';
import {Constants} from "../../provider/Constants";
import { StorgeUtils } from './../../provider/utils/StorageUtils';


@Injectable()
export class WorkspaceService extends BaseService{

  constructor(public api : Api,  public local : StorgeUtils,) {
    super();
  }

  getRegion(){
    this.api.getDict("region")
      .subscribe((res: any) => {
        this.local.set(Constants.LocalStorage.RegionData,JSON.stringify(res));
      }, err => {
      });
  }


  /* 新登录密码格式验证 */
  postPassWordVerify(value) {
    let params = {
      'format_type': 5,
      'data':value
    };
    this.api.updatePassWordFormatVerify(params)
      .subscribe((res: any) => {
        this.page.newPasswordVerify = res.value
      }, err => {
      });
  }
  bindStudent(parmas) {
    this.subscription.add(
      this.api.bindingStudentId(parmas)
        .subscribe((res: any) => {
            this.page.setpdateStudentNo(true);
        }, err => {
          let errorMsg = JSON.parse(err.error._body);
          this.page.showMsg(errorMsg.Errmsg);
        })
    );
  }
  postUpdateUserPassword(params) {
    this.api.updateUserPassword(params)
      .subscribe((res: any) => {
        this.page.setUpdateUserPassword();
      }, err => {
        let errorMsg = JSON.parse(err.error._body);
        this.page.showMsg(errorMsg.Errmsg);
      });
  }

  checkTalentBindNo(accountid) {

    this.subscription.add(
      this.api.getStudentInfo(accountid)
        .subscribe((res: any) => {
          console.log('getStudentInfo',res);
          
         if (res.no) {
          this.page.showUpdateStudentNoLabel()
         }
         this.page.showUpdatePassWordLabel()
        }, err => {
          let errorMsg = JSON.parse(err.error._body);
          // this.page.showMsg(errorMsg.Errmsg);
        })
    );
  }

  getStudentSource(accountid) {

    this.subscription.add(
      this.api.getStudentInfo(accountid)
        .subscribe((res: any) => {
          let params = {
            year: res.year,
            client:0
          };
          console.log('生源信息 ',res);
          this.getStudentMenu(params);
        }, err => {
          let params = {
            year: '',
            client:0
          };
          this.getStudentMenu(params);
          console.log(err,'获取生源信息失败')
        })
    );
  }
  getCollegeSchoolById(collegeId){
    this.subscription.add(
      this.api.getCollegeById(collegeId)
        .subscribe((res: any) => {
          if (res) {
            this.page.updateStudentNoValue.collegeid = res.collegeid;
            this.page.college.collegeid = res.collegeid;
            this.page.collegeInput = res.name;
            this.page.college.workInfoTel = res.work_info.office_tel;
            
          }
        })
    );
  }
  collegeSearch(typeName : string, keyword : string, dict_level : number){
    let params = {
        "name" : keyword,
        "level" : dict_level
    };

    this.subscription.add(
        this.api.matchSearch(typeName,params)
                .subscribe((res: any) => {
                    if(typeName == 'school'){
                        this.page.setCollegeResult(res);
                    }
                    
                }, err => {
                    console.log('error=> ' + err);
                })
    );
}
  /** 获取学校名列表 */
  getCollegeNameList(keyword: string) {
    this.subscription.add(
      this.api.getCollegeNameList({ name: keyword }).subscribe(
        (res: any) => {
          this.page.setCollegeResult(res.list);
        },
        (err) => {
          console.log("error=> " + err);
        }
      )
    );
  }

  getStudentMenu(params) {
    this.subscription.add(
      this.api.getStudentMenu(params)
        .subscribe(async (res: any) => {
          this.page.setStudentMenu(res);
        })
    )
  }



}
